import React from "react"
import Header from "./Header"
import Footer from "./Footer"
import { Helmet } from 'react-helmet' 

export default function Layout(props) {
  return (
    <>
      <Helmet title="WH Serverpark - Individuelles Hosting" defer={false}>
      </Helmet>
      <Header />
      <main>
        {props.children}
      </main>
      <Footer />
    </>
  )
}
