import React from "react"
import { Navbar, Nav, Container } from "react-bootstrap"

import logo from "../images/logo.svg"

export default function Head(props) {
  return (
    <header>
        <Navbar bg="light" variant="light">
          <Container>
          <Navbar.Brand href="/">
            <img src={logo} alt="Logo" />
          </Navbar.Brand>
          <Nav className="ml-auto">
            <Nav.Link href="/#Angebote">Angebote</Nav.Link>
          </Nav>
          </Container>
        </Navbar>
    </header>
  )
}
