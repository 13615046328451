
import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import downloadAgb from '../downloads/AllgemeineGeschftsbedingungen.pdf' 
import downloadAup from '../downloads/AcceptableUsePolicy.pdf'

export default function Footer(props) {


  return (
    <footer>
      <Container>
        <Row>
          <Col xs md={6}>
            <p>
              <b>Wollthan, Hochholdinger GbR</b>
              <br />
              Im Weiher 10
              <br />
              69121 Heidelberg
            </p>
          </Col>
          <Col xs md={6}>
            <p className="text-right">
              <Link activeClassName="active"  rel="nofollow" to="/impressum/">Impressum</Link>
              <Link activeClassName="active" rel="nofollow" to="/datenschutzerklärung/">Datenschutzerklärung</Link>
              <Link to={downloadAgb} download className="inline" rel="nofollow">AGB</Link>
              <Link to={downloadAup} download className="inline" rel="nofollow">AUP</Link>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

